<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Salesmen from "@/components/accounting/accounting-prod/salesmen/index";
import SalesmenAccounting from "@/components/accounting/accounting-prod/salesmen-accounting/index";
import BrokerStatements from "@/components/accounting/accounting-prod/broker-statements/index";
import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "module.CONT.CONP",
      tabs: [
        { name: "Salesmen", text: "Produttori" },
        {
          name: "SalesmenAccounting",
          text: "Lista Registrazioni Manuali Produttori",
        },
        { name: "BrokerStatements", text: "Storico Rendiconti" },
      ],
      tabsGroup: "Management",
      bcs: [
        {
          text: "Contabilità",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Contabilità",
          path: "",
          level: 1,
        },
        {
          text: "Contabilità Produttori",
          path: "",
          level: 2,
        },
        {
          text: "Produttori",
          path: "module.CONT.CONP",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3,
      selected: "Salesmen",
      tabIndex: 0,
    };
  },
  components: {
    Salesmen,
    BrokerStatements,
    SalesmenAccounting,
  },
};
</script>
